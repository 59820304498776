<template>
  <div class="modal is-large is-active">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title has-text-centered">Editar Categoría</p>
        <button class="delete" aria-label="close" type="reset" @click="close()"></button>
      </header>
      <section class="modal-card-body">
        <form>
          <div class="field">
            <label class="label">Categoría</label>
            <div class="control">
              <input
                class="input"
                type="text"
                placeholder="Nombre de la categoría"
                name="name"
                :class="{ 'is-danger': vverrors.first('name') }"
                v-validate="'required'"
                v-model="name"
              >
            </div>
          </div>

          <div class="field">
            <label class="label">Categoría Padre</label>
            <div class="select is-fullwidth" :class="{ 'is-danger': vverrors.first('parent_id') }">
              <select
                class="select"
                v-model="parentId"
                v-validate="'required|excluded:-1'"
                name="parentId"
              >
                <option value="-1">Selecciona una opción</option>
                <template v-for="(category, index) of catalogCategoriesList">
                  <option :value="category.id" :key="index">{{category.name}}</option>
                </template>
              </select>
            </div>
          </div>
        </form>
      </section>
      <footer class="modal-card-foot">
        <div class="field is-grouped">
            <div class="control">
              <button class="button button-givu" @click="editCategoryLocal()">Actualizar</button>
            </div>
            <div class="control">
              <button class="button is-link is-light" @click="close()">Cancelar</button>
            </div>
          </div>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    close: {
      type: Function
    },
    catalogCategoriesList: {
      type: Array
    },
    editElement: {
      type: Object
    },
    editCategory: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      name: null,
      parentId: '-1'
    }
  },
  methods: {
    async editCategoryLocal () {
      let data = {
        id: this.editElement.id,
        name: this.name,
        parentId: this.parentId
      }
      if (await this.$validator.validateAll()) {
        this.editCategory(data)
      }
    }
  },
  beforeMount () {
    this.name = this.editElement.name
    this.parentId = this.editElement.parent_id
  },
  beforeDestroy () {
    this.name = null
    this.parentId = null
  }
}
</script>
